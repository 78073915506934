import React, { Component } from "react";
import SpeechCloud from "./Speechcloud";
import { TweenLite, TimelineLite} from "gsap";

import andyHead from "../images/andy.png";
import mitchHead from "../images/mitch.png";
import vincentHead from "../images/vincent.png";
import marHead from "../images/marjolijn.png";

const heads = [{id: 1, face: mitchHead, name: "Mitch", step1: "Conceptualiseren: Voldoende.", step2: "Verbeelden en maken: Goed. Ik heb bij mijn eerste project hier maximaal aan gewerkt door te experimenteren en prototype's te maken.", step3: "Evalueren: Ruim voldoende. Ik zou mezelf meer willen pushen te vragen naar problemen waar ik tegenaan loop.", step4: "Multidisciplinair samenwerken: Voldoende. Ik heb met 1 project nog maar samengewerkt maar de communicatie met Andy ging goed. Vragen naar feedback is een verbeterpunt.", step5: "Onderzoeken: Goed. Ik heb met deze stagesite al meer nieuwe technieken geleerd dan ik ooit op school gedaan heb." },
  {id: 2, face: andyHead, name: "Andy", step1: "Conceptualiseren: Ruim voldoende", step2: "Verbeelden en maken: Goed", step3: "Evalueren: Ruim voldoende", step4: "Multidisciplinair samenwerken: Goed", step5: "Onderzoeken: Ruim voldoende", step6: "Mitch kan goed zelfstandig handelen en toont veel initiatief"},
  {id: 3, face: vincentHead, name: "Vincent", step1: "Conceptualiseren: Ruim voldoende", step2: "Verbeelden en maken: Ruim voldoende", step3: "Evalueren: Ruim voldoende", step4: "Multidisciplinair samenwerken: Ruim voldoende. Bij meerdere opdrachten heeft Mitch samen moeten werken met het team. Hij kan hierin nog stappen maken maar vindt zijn weg wel.", step5: "Onderzoeken: Ruim voldoende. Mitch bepaalt autonoom hoe hij ontwerpt voor een niet standaard stuk techniek. De plattegrond van huis van verhuur bijvoorbeeld."},
  {id: 4, face: marHead, name: "Marjolein"}];


class Measurement extends Component {
  constructor() {
    super();
    this.stepOneTl = new TimelineLite({ paused: false });
    this.stepTwoTl = new TimelineLite({ paused: true });
    this.headTl = new TimelineLite({paused: true});
    this.firstContent = null;
    this.shadow = null;
    this.convContainer = null;
    this.nav =  null;
    // this.containers = null;
    // this.headContainer = null;
    this.startbutton = null;
    this.heads = {};
    this.secondContent = null;
    this.state = {
      followStyle: { left: "-40%"},
      conversationStarted: false,
      followText: "Introductie",
      count: 0,
      buttonInner: "start",
      secondTitle: "Eerste gesprek",
      convButton: true,
      convCont: "Na de eerste tien weken van de stage kwam Marjolijn langs voor het eerste gesprek. Hierbij werd gemeten hoe ik er voor stond met mijn leerdoelen en de cms competenties. Ik heb hierbij mijn collega andy en stagebegeleider vincent een forumulier in laten vullen over mijn progressie.",
    }


  }

  handleNextItem = (prop) => {
    let pageItem = prop
    let newLocation = ("" + window.location).replace(/#[A-Za-z0-9_]*$/,'') + "#" + pageItem

    if(prop === "second") {
      this.setState({followStyle : {left: "10%" }, followText: "Gesprek"});
      this.stepOneTl.reverse();

      setTimeout(() =>{
        window.location = newLocation;
      }, 600)
      setTimeout(() =>{
        this.stepTwoTl.play();
        this.headTl.play()
      }, 1400)
    }

    else if(prop === "third") {
      this.setState({followStyle : {left: "35%"}, followText: "Eindgesprek"});
      this.stepTwoTl.reverse();
      this.headTl.reverse();
      setTimeout(() => {
        window.location = newLocation
      }, 600)
    }

    else {
      this.setState({followStyle : {left: "-40%"}, followText: "Introductie"});
      this.stepTwoTl.reverse();
      this.headTl.reverse();
      setTimeout(() => {
        window.location = newLocation;


      }, 600)
      setTimeout(() =>{
        this.stepOneTl.play();
      }, 1400)
    }


  }

  conversate = (e) => {
    this.setState({conversationStarted: true, count: this.state.count + 1});
    let firstContainer = this.heads[0].children[0];
    let secondContainer = this.heads[1].children[0];
    let thirdContainer = this.heads[2].children[0];
    let fourthContainer = this.heads[3].children[0];
    setTimeout(() => {
      if(this.state.count === 1) {
        // let contData = [{fir: firstContainer}, {sec: secondContainer}, {thir: thirdContainer}, {four: fourthContainer}];

        this.setState({secondTitle: "Invullen documenten", buttonInner: "Gesprek"})

        // animates width of items
        TweenLite.to(this.convContainer, 0.5, {y: -100, autoAlpha: 0, height: 0});
        TweenLite.to(this.secondContent, .5, {maxWidth: "1000px"});
        TweenLite.to(this.convContainer, 0.5, {display: "none"});
        TweenLite.to(this.shadow, 0.4, {scale: 0});
        TweenLite.to(this.nav, 0.2, {opacity: 0});

        //setTimeout before animate other objects
        setTimeout(() => {
          TweenLite.to(this.nav, 0.2, {display: "none"});
          TweenLite.to(firstContainer, 0.5, {height: "auto", opacity: 1});
          TweenLite.to(secondContainer, 0.5, {height: "auto", opacity: 1});
          TweenLite.to(thirdContainer, 0.5, {height: "auto", opacity: 1});
          TweenLite.to(fourthContainer, 0.5, {height: "auto", opacity: 1});
        }, 400)

      } else if(this.state.count === 2){
        this.setState({secondTitle: "Het Gesprek"});
        TweenLite.to(firstContainer, 0.5, {height: "0", opacity: 1});
        TweenLite.to(secondContainer, 0.5, {height: "0", opacity: 1});
        TweenLite.to(thirdContainer, 0.5, {height: "0", opacity: 1});
        TweenLite.to(this.startbutton, 0.5, {y:100, opacity: 0});
        TweenLite.to(this.nav, 0.3, {display: "flex"});
        TweenLite.to(this.heads[1], 0.5, {y: 50, opacity: 0});


        setTimeout(() => {
          TweenLite.to(this.nav, 0.3, {opacity: 1});
          TweenLite.to(this.shadow, 0.4, {scale: 1});
          this.heads[1].style.display = "none";
          firstContainer.innerHTML = ``;
          // secondContainer.innerHTML = "";
          thirdContainer.innerHTML = `<p>Belangrijk om op tijd te laten weten waar je mee bezig bent voor een klant.</p><p>Probeer aan je werksnelheid te werken.</p>`;
          fourthContainer.innerHTML = `<p>Goed om te horen dat je het zo erg naar je zin hebt</p><p>Een GO voor het vervolg van de stage.</p><p>Kijk nog eens naar alle taken die je wilt voltooien vanuit je stageverslag.</p>`;
          TweenLite.to(firstContainer, 0.5, {height: "auto", opacity: 1});
          TweenLite.to(secondContainer, 0.5, {height: "auto", opacity: 1});
          TweenLite.to(thirdContainer, 0.5, {height: "auto", opacity: 1});
        }, 500)
      }
    }, 300)
  }

  componentDidMount() {
    this.stepOneTl.fromTo(this.firstContent, .6, {y: -50, autoAlpha: 0 }, {y: 0, autoAlpha: 1})
    this.stepTwoTl.fromTo(this.secondContent, .6, {y: -50, autoAlpha: 0}, {y: 0, autoAlpha: 1}).fromTo(this.shadow, .4, {scale: 0}, {scale: 1}, "-=.4")

    for(var i = 0; i < heads.length; i++) {this.headTl.fromTo(this.heads[i], .2, {scale: 0, autoAlpha: 0}, {scale: 1, autoAlpha: 1})}
  }


  render() {
    return (
      <div className={"measure"}>
        <div className="measure__nav" ref={nav => this.nav = nav}>
          <div className={"follow-container"} style={this.state.followStyle}>
            <SpeechCloud innertext={this.state.followText}/>
          </div>
          <div className={"measure__nav-item"} onClick={() => this.handleNextItem("first")}>1</div>
          <div className={"measure__nav-item"} onClick={() => this.handleNextItem("second")}>2</div>
          <div className={"measure__nav-item"} onClick={() => this.handleNextItem("third")}>3</div>
        </div>

        <div id={"first"} className="measure__section first">
          <div className="first__content base-center" ref={div => this.firstContent = div}>
            <h1>Nulmeting</h1>
            <p>Bij de nulmeting werd gekeken of ik mijn leerdoelen voldoende nastreefde en hoe de werkzaamheden gingen naast de CMD competenties. Bekijk hier het hele proces en of ik mijn leerdoelen behaald heb. </p>
          </div>
          <div className="first__background">
          </div>
        </div>

        <div id={"second"} className="measure__section second">
            <div className="second__content base-center" ref={div => this.secondContent = div}>
              <h1>{this.state.secondTitle} </h1>
              <div ref={cont => this.convContainer = cont}>
                <p>{this.state.convCont}</p>
              </div>
              <div className={"block-center"} ref={headcontainer => this.headContainer = headcontainer}>
                <div className={"head-container"}>
                  {heads.map((el, i) => {
                    return <div className={"head-container__card"} ref={head => this.heads[i] = head} key={i}><div className={`convo-container ${el.name}`} ref={text => this.containers = text}>{el.step1 ? <p>{el.step1}</p> : ""}{el.step2 ? <p>{el.step2}</p> : ""}{el.step3 ? <p>{el.step3}</p> : ""}{el.step4 ? <p>{el.step4}</p> : ""}{el.step5 ? <p>{el.step5}{el.step6 ? <p>{el.step6}</p> : ""}</p> : ""}</div> <img className={"head-container__head"} src={el.face} alt=""/> {el.name}</div>
                  })}
                </div>
                <button ref={start => this.startbutton = start} className={`button head-container__button ${this.state.convButton ? "show" : "noshow" }`} onClick={(e) => this.conversate(e)}>{this.state.buttonInner}</button>
                <div className="shadow" ref={shad => this.shadow = shad}></div>
              </div>
            </div>
        </div>

        <div id={"third"} className="measure__section third">
          <div className="third__content base-center">test</div>
        </div>

      </div>
    )
  }
}

export default Measurement;

// Na de eerste tien weken van de stage kwam Marjolijn langs voor het eerste gesprek. Hierbij werd gemeten hoe ik er voor stond met mijn leerdoelen en de cms competenties. I