import React from 'react'
import LogoIq from '../images/logo.png'

const Logo = (props) => {
  const logoStyle = {
    maxWidth: props.width,
    width: "100%"
  }
  return <img className={"logo"} style={logoStyle} src={LogoIq} alt=""/>

}

export default Logo