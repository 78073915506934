import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Home from './Home';
import Objectives from './objectives';
import About from './About';
import Animations from "./Animations";
import BlogPage from "./BlogPage";
import ReactPage from "./ReactPage";
import ReactDetail from "./ReactDetail";
import Measurement from "./Measurement";

const Router = () => {
  return (
    <div>
      <Redirect exact from="/" to="/home" />
      <Route path="/home" component={Home} />
      <Route path="/leerdoelen" component={Objectives} />
      <Route path="/over-iq-media" component={About} />
      <Route path="/anim/:animId" component={BlogPage} />
      <Route path="/react/:id" component={ReactDetail} />
      <Route path="/animations" component={Animations} />
      <Route path="/react-leerweg" component={ReactPage} />
      <Route path="/nul-meting" component={Measurement} />
    </div>
  )
}

export default Router