import React, {Component} from 'react'
import PageHeader from "./Page-header"
import {default as AnimCards} from './Cards'

class Animations extends Component {
  constructor() {
    super();
    this.state = {
      animateContent: false,
      nod: false
    }
  }

  componentDidMount() {
    this.setState({animateContent: true})

  }

  activateNod = () => {
    console.log("hover")
      this.setState({nod: true})
    setTimeout(() => {
      this.setState({nod: false})
    }, 400)

  }

  render() {
    return (
      <div className={"animations white"}>
        <PageHeader circle={true} height={"350px"} color={"#1c1c1c"}/>
        <div className={`container ${this.state.animateContent ? "show-content" : ""}`}>
          <div className="row">
            <h1>Leerweg Animaties</h1>
            <p>Alle animaties die ik mezelf aangeleerd heb tijdens de stage.</p>
            <p>Elke keer dat ik hier mee bezig was gedocumenteerd en bewerkt.</p>
            <AnimCards hover={this.activateNod} />
          </div>
        </div>
        <div className="character">
          <svg className="devil" viewBox="0 0 512.041 512.041" xmlns="http://www.w3.org/2000/svg">
            <g className={`bodyback ${this.state.nod ? "nod" : ""}`}>
              <path
                d="M257.006 331.334c-23.413 0-45.676 4.912-65.827 13.748l-14.345 82.373 14.345 63.634h129.683l16.95-63.634-16.95-83.231c-19.627-8.3-41.205-12.89-63.856-12.89z"
                fill="#525e77"/>
              <path
                d="M257.006 331.334c-23.413 0-45.676 4.912-65.827 13.748l-14.345 82.373 4.426 19.634a174.661 174.661 0 0 0 33.917 3.316h81.445c12.399 0 24.476-1.3 36.075-3.75l5.114-19.2-16.95-83.231c-19.626-8.3-41.204-12.89-63.855-12.89z"
                fill="#3b4456"/>
              <path
                d="M95.549 466.017c-2.367 13.082 7.855 25.072 21.149 25.072h74.481V345.082c-49.207 21.577-85.799 66.596-95.63 120.935z"
                fill="#3b4456"/>
              <path
                d="M113.02 416.656c21.842 16.602 48.707 28.043 78.159 32.106v-103.68c-33.281 14.594-61.261 39.258-78.159 71.574z"
                fill="#2e3747"/>
              <path
                d="M418.464 466.017c-9.962-55.063-47.4-100.565-97.602-121.793v146.865h76.453c13.294-.001 23.516-11.991 21.149-25.072z"
                fill="#3b4456"/>
              <path
                d="M400.334 415.477c-17.114-32.562-45.722-56.982-79.472-71.254v104.505c30.057-4.19 57.414-16.049 79.472-33.251z"
                fill="#2e3747"/>
            </g>

            <g className={`head ${this.state.nod ? "nod" : ""}`}>
              <g className="horn-right">
                <path
                  d="M437.508 198.641c49.482-25.431 70.98-99.082 66.414-150.279-1.527-17.127-12.227-28.023-28.857-18.049l-75.512 45.288c-47.655 28.58-30.666 158.307 37.955 123.04z"
                  fill="#525e77"/>
                <path
                  d="M443.39 49.309L399.552 75.6c-47.655 28.581-30.665 158.308 37.955 123.041 24.956-12.826 42.794-37.917 53.76-66.208-6.243-37.073-36.45-76.661-47.877-83.124z"
                  fill="#3b4456"/>
              </g>
              <g className="horn-left">
                <path
                  d="M74.533 198.641C25.052 173.21 3.553 99.559 8.119 48.362c1.527-17.127 12.227-28.023 28.857-18.049L112.488 75.6c47.655 28.581 30.666 158.308-37.955 123.041z"
                  fill="#525e77"/>
                <path
                  d="M68.651 49.309L112.489 75.6c47.655 28.581 30.665 158.308-37.955 123.041-24.956-12.826-42.794-37.917-53.76-66.208 6.243-37.073 36.45-76.661 47.877-83.124z"
                  fill="#3b4456"/>
              </g>
              <g className={`ears ${this.state.nod ? "nod" : ""}`}>
                <ellipse className="ear-left" cx="76.677" cy="268.401" fill="#ffbead" rx="46.594" ry="46.594"
                         transform="rotate(-84.32 76.751 268.468)"/>
                <ellipse className="ear-right" cx="435.364" cy="268.401" fill="#ffcbbe" rx="46.594" ry="46.594"
                         transform="rotate(-84.32 435.548 268.4)"/>
              </g>
              <path
                d="M292.524 411.211h-73.248c-78.476 0-142.683-64.207-142.683-142.683V195.28c0-78.476 64.207-112.559 142.683-112.559h73.248c78.476 0 142.683 34.083 142.683 112.559v73.248c0 78.476-64.208 142.683-142.683 142.683z"
                fill="#ffddcf"/>
              <path
                d="M219.276 82.721c-78.476 0-142.683 34.083-142.683 112.559l-1 73.248c0 60.583 39.267 112.663 92.823 133.262-82.748-124.639 28.335-222.124 50.86-319.069z"
                fill="#ffcbbe"/>
              <g className={`cheecks ${this.state.nod ? "nod" : ""}`} fill="#ffb6c1">
                <ellipse cx="147.025" cy="292.069" rx="29.809" ry="23.221"/>
                <ellipse cx="365.016" cy="292.069" rx="29.809" ry="23.221"/>
              </g>
              <g className={`hairs ${this.state.nod ? "nod" : ""}`}>
                <path
                  d="M102.341 224.816c-6.625 6.462-14.428 13.023-23.682 13.078-7.574.045-14.638-4.508-19.192-10.561-4.554-6.052-6.93-13.457-8.612-20.842-8.086-35.492-.539-73.665 18.433-104.732 37.559-61.503 88.347-44 100.647-28.526 28.665 36.061-17.545 92.972-39.999 121.094a332.074 332.074 0 0 1-27.595 30.489zM283.357 378.526c0 15.164-12.293 48.929-27.458 48.929-15.164 0-27.458-33.764-27.458-48.929 0-15.164 12.293-27.458 27.458-27.458s27.458 12.294 27.458 27.458z"
                  fill="#fd5c6f"/>
                <path
                  d="M145.685 174.107c22.353-30.102 47.107-72.117 24.249-100.873-5.945-7.479-20.881-15.432-38.968-14.71-19.701 31.521-10.847 85.089 14.719 115.583z"
                  fill="#f9475e"/>
                <path className={`hair-front ${this.state.nod ? "nod" : ""}`}
                      d="M206.616 189.109c-14.61-12.91-26.936-28.23-36.832-45.316C125.417 67.197 202.805 30.734 268.922 22.4c85.406-10.766 150.923 36.079 181.573 100.379 14.447 28.541 13.024 66.785-.616 95.377-5.212 10.925-17.34 16.756-29.102 13.896-31.517-7.663-62.321-17.064-91.936-28.057-6.497-2.412-13.396 2.419-13.282 9.348l.008.485c.224 13.079-12.359 22.631-24.866 18.802-29.252-8.957-61.762-21.514-84.085-43.521z"
                      fill="#fd5c6f"/>
                <path
                  d="M254.19 385.196c.243-10.73 3.096-23.532 9.607-32.982a27.45 27.45 0 0 0-7.897-1.146c-15.164 0-27.458 12.293-27.458 27.458s12.293 48.929 27.458 48.929c3.24 0 6.351-1.544 9.237-4.115-8.101-10.397-11.247-24.897-10.947-38.144z"
                  fill="#f9475e"/>
              </g>
              <path className={`faced ${this.state.nod ? "nod" : ""}`}
                    d="M238.24 306.473c7.286 0 13.882-3.001 18.625-7.829 4.743 4.828 11.339 7.829 18.625 7.829 14.406 0 26.125-11.72 26.125-26.125a7.5 7.5 0 0 0-15 0c0 6.135-4.991 11.125-11.125 11.125s-11.125-4.991-11.125-11.125a7.5 7.5 0 0 0-15 0c0 6.135-4.991 11.125-11.125 11.125s-11.125-4.991-11.125-11.125a7.5 7.5 0 0 0-15 0c0 14.405 11.719 26.125 26.125 26.125zM168.42 280.224a7.5 7.5 0 0 0 7.5-7.5v-13.975a7.5 7.5 0 0 0-15 0v13.975a7.5 7.5 0 0 0 7.5 7.5zM345.312 280.224a7.5 7.5 0 0 0 7.5-7.5v-13.975a7.5 7.5 0 0 0-15 0v13.975a7.5 7.5 0 0 0 7.5 7.5z"/>
            </g>
          </svg>
        </div>
      </div>
    )}
}

export default Animations