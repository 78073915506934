import React, {Component} from 'react';

class Objectives extends Component {
  constructor() {
    super()
    this.state =  {
      animateContent: false,
      openUp: false,
      levitate: true,
      layer: false,
      leerdoelen: [
        {id: 1, competention:"CMD Competentie: Verbeelden en maken", competention_desc: "Doormiddel van het sneller toe kunnen passen van code doormiddel van React kunnen sneller prototype’s gebouwd worden en getest waardoor in de toekomsige producten beter opgeleverd worden.", objective: " Binnen 20 weken kan ik een website ontwikkelen met het framework React zodat ik bij toekomstige projecten gelijk invulling kan bieden. Ik zal tijdens mijn stage elke week een betaalde tutorial volgen via Udemy en hiernaast een stagewebsite ontwikkelen over mijn ervaring bij IQ-Media waarin ik deze nieuwe technieken toe pas." },
        {id: 2, competention:"CMD Competentie: Onderzoeken/ Conceptualiseren", competention_desc: "Doordat ik meer kennis heb van de nieuwste ontwikkelingen kan ik sneller aanvulling bieden aan het team en meepraten over nieuwe technieken. Hierdoor sta ik beter op eigen benen.", objective: "Ik kan over 10 weken sneller oplossingen onderzoeken en deze toepassen voordat ik een collega moet raadplegen. Dit doe ik door elke week een artikel te lezen over nieuwe trends op het gebied van front-end en hier mijn mening over te uiten op de stagewebsite." },
        {id: 3, competention:"CMD Competentie: Ontwikkelen en reflecteren.", competention_desc: "", objective: "Tegen het eind van de stage kan ik Javascript schrijven in de ES6 syntax. Elk bedrijf werkt in deze syntax waardoor bepaalde functies sneller te volbrengen zijn. Ik zal dit doen door elke week te proberen een stuk code te laten reviewen door een collega. Het leerdoel zal volbracht zijn zodra mijn nulmeting als positief ingevuld wordt.\n" },
        {id: 4, competention:"CMD Competentie: Multidisciplinair samenwerken.", competention_desc: "", objective: "Binnen 15 weken weet ik hoe het klanten traject verloopt bij IQ-Media en welke processen voltooid worden. Dit doe ik door mijn stagebegeleider te interviewen, het proces tijdens mijn samenwerking op te schrijven en door een vergadering bij te wonen en hier een klein verslag van te maken die op de stage website komt te staan." },
        {id: 5, competention:"CMD Competentie: Evalueren", competention_desc: "", objective: "Ik kan binnen 10 weken syntax correcte code schrijven die voor elke collega te begrijpen is. Door het evalueren van mijn eigen code na een opdracht en dit te verbeteren waar mogelijk. Leerdoel is gehaald zodra mijn code vaardigheden bij de eindebeoordeling positief bevonden zijn door Mark en Vincent." },
        {id: 6, competention:"CMD Competentie: Verbeelden en maken", competention_desc: "", objective: "Binnen 10 weken kan ik een custom wordpress thema opleveren naar de wensen van de klant. Dit leerdoel is behaald door naast het bouwen van de stagewebsite ook een custom website op te zetten waarin mijn logboek staat en alle opdrachten die ik volbracht heb om leerdoel 1 te behalen." },
        {id: 7, competention:"CMD Competentie: Onderzoeken", competention_desc: "", objective: "Binnen 20 weken wil ik mijn kennis op het gebied van webanimaties verbreden. Dit doe ik door onderzoek te doen naar nieuwe animaties en dit door te voeren op de stagewebsite waar ook de vooruitgang op te zien is." }],
    }
  }

  componentDidMount() {
    this.setState({animateContent: true})
  }

  openUp = (num) => {
    if (this.state.openUp) {
      this.setState({ openUp: false }, () => {
        setTimeout(() => {
          this.setState({ levitate: true, layer: false });
        }, 300);
      });
      return;
    }
    this.setState({ openUp: num, layer: num }, () => {
      setTimeout(() => {
        this.setState({ levitate: false});
      }, 300);
    });
  }

  render() {
    return (
      <div className="objectives black">
        <div className={`container ${this.state.animateContent ? "show-content" : ""}`}>
          <h1>Objectives</h1>
          <p>Voor de stage heb ik verschillende leerdoelen opgezet. Elk leerdoel heeft zijn eigen tijd waarin ik het hoop te behalen en een CMD competentie die hieraan gekoppeld is.</p>
        </div>
        <div className="objectives__block">
          {this.state.leerdoelen.map((leerdoel, i) => {
            return (
              <div onClick={() => { this.openUp(i + 1); }} key={i} className={`objectives__item item-${i + 1} ${this.state.openUp === i + 1 ? "open-up" : ""} ${this.state.layer === i + 1 ? "top-layer" : ""} ${this.state.levitate ? "levitate" : ""}`}>
                <div className={`objectives__item-center ${this.state.openUp ? "show" : "" }`}>
                  <h1>Leerdoel</h1>
                  <p><strong>{i + 1}.</strong> {leerdoel.objective ? leerdoel.objective : ""}</p>
                  <strong><p>{leerdoel.competention ? leerdoel.competention : ""}</p></strong>
                  <p>{leerdoel.competention_desc ? leerdoel.competention_desc : ""}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  }

} 

export default Objectives