import React, { Component } from "react";
import MenuItem from "./MenuItem";
import axios from "axios";
import animImage from "../images/animation3.jpg.png";
import homeImage from "../images/home-back.png";
import ReactImage from "../images/react-back.png";
import ObjectiveImage from "../images/objectives.png";
import AboutImage from "../images/about.png";
import HomePageImage from '../images/home.png';

import { ReactComponent as Hamburger } from "../images/menu-icon.svg";
import { ReactComponent as Forbidden } from "../images/forbidden-mark.svg";

class MainNav extends Component {
  constructor() {
    super();
    this.state = {
      mainNav: [],
      menuOpen: false,
      animated: false,
      menuItems: false,
      slideIn: false,
      circleBackground: "",
      rotate: false
    };
  }

  handleMenu = () => {
    if (this.state.menuOpen === false) {
      this.setState({ menuOpen: true, circleBackground: homeImage });
      setTimeout(() => {
        this.setState({ animated: true, menuItems: true, slideIn: true });
      }, 300);
    } else if (this.state.menuOpen === true) {
      this.setState({
        menuItems: false,
        slideIn: false,
      });
      setTimeout(() => {
        this.setState({ animated: false});
      }, 300);
      setTimeout(() => {
        this.setState({ menuOpen: false, circleBackground: homeImage });
      }, 300);
    }
  };

  changeImage = props => {
    this.setState({ rotate: false });
    if (props === "Home") {
      this.setState({ circleBackground: HomePageImage });
      this.setState({ rotate: true });
    } else if (props === "Animatie Blog") {
      this.setState({ circleBackground: animImage });
      this.setState({ rotate: true });
    } else if (props === "React Blog") {
      this.setState({ circleBackground: ReactImage });
    } else if(props === "Over IQ-Media") {
      this.setState({ circleBackground: AboutImage });
    } else if(props === "Leerdoelen") {
      this.setState({ circleBackground: ObjectiveImage });
    }
  };

  componentDidMount() {
    let dataURL =
      "https://mitchgoudkuil.nl/iqmedia/backend/wp-json/wp-api-menus/v2/menus/2";
    axios.get(dataURL).then(res => {
      this.setState({
        mainNav: res.data.items
      });
    });
  }

  render() {
    const { menuOpen, animated, menuItems, slideIn, rotate } = this.state;
    return (
      <div className="nav">
        <div onClick={this.handleMenu} className="hamburger">
          <Hamburger className={`hamburger-icon ${this.state.menuOpen ? "none" : ""}`} />
          <Forbidden className={`hamburger-icon ${!this.state.menuOpen ? "none" : ""}`} />
        </div>
        <div
          className={`fullsize ${menuOpen ? "anim-in" : ""} ${
            animated ? "open" : ""
          }`}
        >
          <div
            className={`background-circle ${slideIn ? "slide-in" : ""} ${
               menuItems ? "show" : ""
            } ${rotate ? "rotate" : ""}`}
            style={{
              backgroundImage: "url(" + this.state.circleBackground + ")"
            }}
          />
          <ul className={`mainnav ${menuItems ? "show" : "closed"}`}>
            <h1 className="nav__title">MENU</h1>
            {this.state.mainNav.map((item, index) => (
              <li
                key={index}
                onClick={e => this.handleMenu(e)}
                onMouseOver={e => this.changeImage(item.title)}
                className="mainnav__item"
              >
                <MenuItem title={item.title} slug={item.object_slug} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default MainNav;
