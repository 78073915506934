import React, { Component } from 'react';
import {NavLink} from "react-router-dom";

export default class AnimCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animations: [],
      animateContent: false,
      hover: false,
      props: props
    }
  }

  componentDidMount() {
    let dataURL = "https://mitchgoudkuil.nl/iqmedia/backend/wp-json/wp/v2/animations";
    fetch(dataURL)
      .then(res => res.json())
      .then(res => {
        this.setState({
          animations: res,
          animateContent: true
        })
      })
  }

  render(props) {
    console.log(props)
    let animations = this.state.animations.map((animation, index) => {
      return <NavLink onMouseOver={this.state.props.hover}  to={{pathname:"/anim/" + animation.id}} className="card-container__card" key={index} style={{backgroundImage: `url(${animation.acf.image})`}}>
        <div>
          <h6>{animation.title.rendered}</h6>
        </div>
      </NavLink>
    })
    return (
      <div className={`card-container ${this.state.animateContent ? "show-cards" : ""}`}>
        {animations}
      </div>
    )
  }
}

export class ReactCards extends Component {
  constructor() {
    super();
    this.state = {
      animations: [],
      animateContent: false
    }
  }

  componentDidMount() {
    let dataURL = "https://mitchgoudkuil.nl/iqmedia/backend/wp-json/wp/v2/leerweg_react";
    fetch(dataURL)
      .then(res => res.json())
      .then(res => {
        console.log(res)
        this.setState({
          animations: res,
          animateContent: true
        })
      })
  }

  render() {
    let reactStories = this.state.animations.map((animation, index) => {
      return <NavLink to={{pathname:"/react/" + animation.id}} className={`card-container__card ${this.state.animateContent ? "show-cards" : ""}`} key={index} style={{backgroundImage: `url(${animation.acf.image_react})`}}>
        <h6>{animation.title.rendered}</h6>
      </NavLink>
    })
    return (
      <div className={`card-container`}>
        {reactStories}
      </div>
    )
  }
}

