import React, { Component } from "react";
import BackButton from "./BackButton";

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageID: props.location.pathname,
      data: "",
      skinnedData: [],
      animateContent: false
    };
  }

  componentDidMount() {
    let getID = this.state.pageID.slice(6, 10);
    let dataURL =
      "https://mitchgoudkuil.nl/iqmedia/backend/wp-json/wp/v2/animations/" + getID;
    fetch(dataURL)
      .then(res => res.json())
      .then(res => {
        this.setState({
          data: res.acf,
          animateContent: true
        });
      });
  }

  render() {
    return (
      <div className={"story"}>
        <BackButton link={"animations"} />
        <div
          className={`container ${
            this.state.animateContent ? "show-content" : ""
          }`}
        >
          <div className="container__2-col">
            <div className={"story__content"}>
              <div className={"story__title"}>
                <div className={"story__number"}>
                  <h6>{this.state.data.number}</h6>
                </div>
                <h1>{this.state.data.title}</h1>
              </div>
              <p className={"intro"}>{this.state.data.intro}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.data.beschrijving
                }}
              />
              <div className="embed-container">{this.state.data.anim_codepen}</div>
            </div>
            <div className={"story__image"}>
              <div
                className="story__image-circle"
                style={{
                  backgroundImage: "url(" + this.state.data.image + ")"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogPage;
