import React from 'react';


const SpeechCloud = (props) => {
  console.log(props)
  return <div className={"speech-cloud"}>
    <p className={"speech-cloud__inner"}>{props.innertext}</p>
    <div className={"speech-cloud__triangle"}></div>
  </div>
}

export default SpeechCloud