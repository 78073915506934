import React, { Component } from "react";
import BackButton from "./BackButton";
import PageHeader from "./Page-header";

import Logo from "./Logo";
import reactLogo from "../images/react-logo.svg"


class ReactDetail extends Component {
  constructor(props) {
    super(props);
    this.logoRef = React.createRef()
    this.state = {
      pageID: props.location.pathname,
      data: "",
      animateContent: false
    };
  }

  componentDidMount() {
    let getID = this.state.pageID.slice(7, 10);
    let dataURL =
      "https://mitchgoudkuil.nl/iqmedia/backend/wp-json/wp/v2/leerweg_react/" + getID;
    fetch(dataURL)
      .then(res => res.json())
      .then(res => {
        this.setState({
          data: res.acf,
          animateContent: true
        });
      });
  }



  // mouseFollow = (e) => {
  //   var logo = this.logoRef.current;
  //   console.log(logo)
  //   var beepos = {x:50, y:50};
  //   setInterval(this.mouseFollow, 50);
  //   var mouse = {x:0, y:0};
  //
  //   let distX = mouse.x - beepos.x;
  //   let distY = mouse.y - beepos.y;
  //   beepos.x += distX/5;
  //   beepos.y += distY/2;
  //
  //   logo.style.left = beepos.x + "px";
  //   logo.style.top = beepos.y + "px";
  //   console.log(e)
  //   onMouseMove={(e) => this.mouseFollow(e)} <======== functie
  // }

  render() {
    return (
      <div
        className={`react-story ${
          this.state.animateContent ? "show-content" : ""
        }`}
      >
        <img ref={this.logoRef} src={reactLogo} alt=""/>
        <BackButton link={"react-leerweg"} />
          <PageHeader height={"200px"} img={true} />
        <div className="react-story__logo">
          <Logo width={"60px"} />
        </div>
        <div
          className={`container ${
            this.state.animateContent ? "show-content" : ""
          }`}
        >
          <div className={"react-story__content"}>
            <div className={"react-story__number"}>
              <h6>{this.state.data.number_react}</h6>
            </div>
            <h1>{this.state.data.title_react}</h1>
            <h5>{this.state.data.intro_react}</h5>
            <div>
              <p dangerouslySetInnerHTML={{
                __html: this.state.data.beschrijving_react}} className={"text__2-col"}/>
            </div>
          </div>
          <div className="react-story__link">
            <strong>
              <a
                target="_blank"
                href="https://www.udemy.com/course/react-redux/"
              >
                Tutorial: Modern React with Redux on Udemy
              </a>
            </strong>
          </div>
        </div>
      </div>
    );
  }
}

export default ReactDetail;
