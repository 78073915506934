import React, {Component} from 'react'
import PageHeader from "./Page-header"
import {ReactCards} from "./Cards";

class ReactPage extends Component {
  constructor() {
    super();
    this.state = {
      animateContent: false

    }
  }

  componentDidMount() {
    this.setState({animateContent: true})
  }

  render() {
    return (
      <div className={"react white"}>
        <PageHeader circle={true} color={"#1c1c1c"}/>
        <div className={`container ${this.state.animateContent ? "show-content" : ""}`}>
          <div className="row">
            <h1>Leerweg React</h1>
            <p>Tijdens de stage had ik het leerdoel om het framework React te leren. Dit wilde ik doen door elke week een betaalde tutorial te kijken en deze website te maken tussendoor. Om mijn progressie van deze website te laten zien heb ik besloten hierover te schrijven en dit op deze pagina te plaatsen</p>
            <ReactCards />
          </div>
        </div>
      </div>
    )
  }
}

export default ReactPage