import React, { Component } from "react";
import Logo from "./Logo";
import frontEndImg from '../images/frontend.png';
import animImg from '../images/12.jpg';
import videoImg from '../images/video-prod.jpg';
import designImg from '../images/design.jpg';
import bearSvg from '../images/bear.svg';
import cursorSvg from '../images/cursor.svg';

//logo import

import foxLogo from '../images/fox.png'
import kruidLogo from '../images/kruidvat.png'
import redBLogo from '../images/redbull.png'
import heroLogo from '../images/Logo_Hero.svg'
import rtlLogo from '../images/rtl.png'
import pecLogo from '../images/pec.png'

class About extends Component {
  constructor() {
    super();
    this.state = {
      title: "Over IQ-media",
      smalldescription: "IQ-Media bestaat uit 40 vakspecialisten die dagelijks werken aan realisaties van verschillende media uitingen.",
      description:
        "IQ-Media is een full-service marketing-, media- & reclamebureau gespecialiseerd in de strategische inzet en productie van hedendaagse marketing- en communicatiemiddelen. Vanaf een startfase waarin de basiselementen van een bedrijf of merk als een huis moeten staan tot aan het bedenken en uitrollen van grote en innovatieve strategieën.",
      click: 1,
      width500: true,
      width600: false,
      width650: false,
      animateIn: false,
      animateContent: false,
      feImage: frontEndImg,
      animImage: animImg,
      videoImage: videoImg,
      designImage: designImg,
      animateOut: false,
      logo: "250px"
    };
  }

  replaceVerticalScrollByHorizontal = () => {
    this.setState({ click: this.state.click + 1 });
    console.log(this.state.click)
    if (this.state.click === 1) {
      this.setState({
        title: "Specialisaties",
        logo: "150px",
        width500: false,
        width600: true,
        smalldescription: "",
        description:
          "Aangezien IQ-Media alle specialisaties in 1 gebouw werkend heeft is het mogelijk producten op te leveren van hoge kwaliteit. Erg belangrijk is de samenwerking binnen het team om zo gezamelijk het juiste eindproduct te realiseren."
      });
    } else if (this.state.click === 2) {
      this.setState({
        title: "klanten",
        width600: false,
        description: "IQ-Media heeft door de verschillende specialisaties ook een groot arrangement aan klanten. Hieronder staat een impressie van de klanten waarvoor IQ-Media gewerkt heeft. ",
        smalldescription: "",
        width650: true
      });
    }
  };

  resetAbout = () => {
    this.setState({
      title: "Over IQ-media",
      click: 1,
      logo: "250px",
      width500: true,
      width650: false,
      smalldescription: "IQ-Media bestaat uit 40 vakspecialisten die dagelijks werken aan realisaties van verschillende media uitingen.",
      description:
        "IQ-Media is een full-service marketing-, media- & reclamebureau gespecialiseerd in de strategische inzet en productie van hedendaagse marketing- en communicatiemiddelen. Vanaf een startfase waarin de basiselementen van een bedrijf of merk als een huis moeten staan tot aan het bedenken en uitrollen van grote en innovatieve strategieën.",
    });
  };

  componentDidMount() {
    this.setState({ animateIn: true, animateContent: true });
  }

  render() {
    let clickCheck = this.state.click;
    let goBackButton;

    if (clickCheck > 2) {
      goBackButton = "Naar het begin";
    }

    return (
      <div className={"about"}>
        <div className={`container ${this.state.animateContent ? "show-content" : ""}`}>
          <div onClick={this.replaceVerticalScrollByHorizontal}
            className={`about__block ${this.state.width500 ? "max-500" : ""} ${
              this.state.width600 ? "max-600" : ""
            } ${this.state.width650 ? "max-650" : ""}`}
          >
            <Logo width={this.state.logo} />
            <div className={`about__block-content ${
              this.state.animateIn ? "animate-in" : ""
            } ${
              this.state.animateOut ? "animate-out" : ""
            }`}>
              <div className={`part-1`}>
                <h1>{this.state.title}</h1>
                <p>{this.state.description}</p>
                <p>{this.state.smalldescription}</p>
                <div className={`specials ${this.state.width600 ? "showtext" : ""}`}>
                  <div className={"specials__card"} style={{backgroundImage: "url(" + this.state.feImage + ")"}}>
                    <h6>Front-end</h6>
                  </div>
                  <div className={"specials__card"} style={{backgroundImage: "url(" + this.state.animImage + ")"}}>
                    <h6>Animaties</h6>
                    <div className="face">
                      <img src={bearSvg} alt=""/>
                    </div>
                  </div>
                  <div className={"specials__card"} style={{backgroundImage: "url(" + this.state.designImage + ")"}}>
                    <h6>Design</h6>
                  </div>
                  <div className={"specials__card"} style={{backgroundImage: "url(" + this.state.videoImage + ")"}}>
                    <h6>Video-Productie</h6>
                  </div>
                </div>
                <div className={`clients ${this.state.width650 ? "visible" : ""}`}>
                  <div className="clients__client"><img src={pecLogo} alt=""/></div>
                  <div className="clients__client"><img src={kruidLogo} alt=""/></div>
                  <div className="clients__client"><img src={foxLogo} alt=""/></div>
                  <div className="clients__client"><img src={heroLogo} alt=""/></div>
                  <div className="clients__client"><img src={redBLogo} alt=""/></div>
                  <div className="clients__client"><img src={rtlLogo} alt=""/></div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <div className={`bottom-container__click ${this.state.width650 ? "noshow" : "" }`}>
              <img className={"clickIcon"} src={cursorSvg} alt=""/>
              <p>Klik content</p>
            </div>

            <p onClick={this.resetAbout} className={"restart-button"}>
              {goBackButton}
            </p>
          </div>

        </div>
      </div>
    );
  }
}

export default About;
