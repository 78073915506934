import React, { Component } from "react";
import worldImg from "../images/world.svg";

class World extends Component {
  constructor() {
    super();
    this.state = {
      filled: false
    };
  }

  componentDidMount() {
    this.setState({ filled: true });
  }

  render() {
    return (
      <div>
        <svg className={"world"} data-name="Laag 1" viewBox="0 0 401.8 401.8">
          <path
            className={"circle-back"}
            d="M8,200.9a192.9,192.9 0 1,0 385.8,0a192.9,192.9 0 1,0 -385.8,0"
          />
          <path
            className={"cls-2"}
            d="M8,200.9a192.9,192.9 0 1,0 385.8,0a192.9,192.9 0 1,0 -385.8,0"
          />
          <path
            className="cls-2"
            d="M201.45,100.36a91.61,91.61,0,0,0,89-69.86,193.18,193.18,0,0,0-177.95,0A91.6,91.6,0,0,0,201.45,100.36Z"
            transform="translate(-0.55 -0.79)"
          />
          <path
            className="cls-2"
            d="M372.64,112.71a91.59,91.59,0,0,0,0,178,193.18,193.18,0,0,0,0-178Z"
            transform="translate(-0.55 -0.79)"
          />
          <path
            className="cls-2"
            d="M201.45,303a91.59,91.59,0,0,0-89,69.86,193.18,193.18,0,0,0,178,0A91.6,91.6,0,0,0,201.45,303Z"
            transform="translate(-0.55 -0.79)"
          />
          <path
            className="cls-2"
            d="M100.12,201.69a91.61,91.61,0,0,0-69.86-89,193.18,193.18,0,0,0,0,178A91.6,91.6,0,0,0,100.12,201.69Z"
            transform="translate(-0.55 -0.79)"
          />
        </svg>
        <img
          className={`world-filled ${this.state.filled ? "fill-up" : ""}`}
          src={worldImg} alt={"World with items on them."}
        />
      </div>
    );
  }
}

export default World;
