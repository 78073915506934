import React from 'react';
import spaceImg from '../images/space.jpg'

const PageHeader = (props) => {
return (
  <div className="header" style={{backgroundColor: props.color, height: props.height, backgroundImage: `url(${props.img ? spaceImg : ""})` }}>
    <div className={`header__circle ${props.circle ? "show" : ""}`}>
    </div>
  </div>
)
}

export default PageHeader