import React, { Component } from "react";
import Logo from "./Logo";
import axios from "axios";
import {NavLink} from "react-router-dom";
import World from "./World";
import animationImage from '../images/home-cards/animation.png';
import reactImage from '../images/home-cards/react.png';
import aboutImage from '../images/home-cards/about.png';
import objectiveImage from '../images/home-cards/objectives.png'
import arrowIcon from '../images/arrow-back-filled.svg';
import scrollIcon from '../images/scroll-icon.svg';


let homeDataArray = [
  {title: "Animaties", id: 1, image: animationImage, link: "animations"},
  {title: "Leren React", id: 2, image: reactImage, link: "react-leerweg"},
  {title: "Over IQ-Media", id: 3, image: aboutImage, link: "over-iq-media"},
  {title: "Leerdoelen", id: 4, image: objectiveImage, link: "leerdoelen"},
]

class Home extends Component {
  constructor() {
    super();
    this.state = {
      homeData: [],
      fixed: false,
      logoWidth: "250px",
      animateContent: false,
      animImage: animationImage,
      rotatePoint: 0,
      contentPoint: 0,
      dataArray: homeDataArray.length ,
      animate: false,
      cardTitle: "Animations",
      cardImage: animationImage,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.animateOnscroll)
    this.setState({
      contentPoint: 0
    })
    let dataURL =
      "https://mitchgoudkuil.nl/iqmedia/backend/wp-json/wp/v2/pages/?slug=home";
    axios.get(dataURL).then(res => {
      this.setState({
        homeData: res.data[0].acf,
        animateContent: true
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.animateOnscroll)
  }

  animateOnscroll = (event) => {
    let pageOffset = window.pageYOffset;
    let windowHeight = window.innerHeight;

    console.log(pageOffset)
    if (pageOffset > 400 && pageOffset < 700) {
     this.setState({fixed: true})
    } else if(pageOffset > 700 && pageOffset < windowHeight) {
      console.log("groter dan 900")
    } else {
      this.setState({fixed: false, logoWidth: "250px"})
    }
  }

  rotate = (direction) => {

    if (direction === "left") {
      this.setState({
        rotatePoint: this.state.rotatePoint - 90,
        contentPoint: (this.state.contentPoint - 1) % homeDataArray.length,
        animate: true
      })
      setTimeout(() => {
        this.setState({
          animate: false
        })
      },600)
    } else if (direction === "right") {
      this.setState({
        rotatePoint: this.state.rotatePoint + 90,
        contentPoint: (this.state.contentPoint + 1) % homeDataArray.length,
        animate: true
      })
      setTimeout(() => {
        this.setState({
          animate: false
        })
      },600)
    }
  }

  render() {
    let worldStyle = {
      transform: `rotate(${this.state.rotatePoint}deg)`,
      color: "green"
    }

    const data = homeDataArray[this.state.contentPoint]

    return (
      <div className={"home__parallax"}>
        <div className={`home black full-height`}>
          <div className={`scroll ${this.state.fixed ? "no-show" : ""}`}>
            <img src={scrollIcon} alt=""/><p>Scroll</p>
          </div>
          <div
            className={`container ${
              this.state.animateContent ? "show-content" : ""
            }`}
          >
            <div className="container__2-col foreground">
              <div>
                <h1>{this.state.homeData.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: this.state.homeData.description_home }} />
              </div>
              <div>
                <Logo width={`250px`} />
              </div>
            </div>
          </div>
          <div className={`carousel-home`}>
            <NavLink to={`/${data.link}`}>
            <div className={`carousel-home__card ${this.state.animate ? "noheight" : "" }`} style={{backgroundImage: `url(${data.image})`}}>
              <h6 className={`${this.state.animate ? "noshow" : "" }`}>{data.title}</h6>
            </div>
            </NavLink>
            <p className={`carousel-home__numbers`}>{data.id} van {this.state.dataArray}</p>
            <div className={`carousel-home__shadow ${this.state.animate ? "slink" : ""}`}></div>

            <img onClick={(e) => this.rotate("left")} className={`carousel-home__button carousel-home__button-left ${this.state.contentPoint === 0 ? "unclickable" : ""}`} src={arrowIcon} alt=""/>
            <img onClick={(e) => this.rotate("right")} className={"carousel-home__button carousel-home__button-right flipped"} src={arrowIcon} alt=""/>
          </div>
          <div className={`background-circle-container`}>
            <div className={`background-world`} style={worldStyle}>
            <World />
            </div>
          </div>
        </div>

        <div className="home__second white">
          <div className={`logo-container ${this.state.fixed ? "logo-move" : "" }`}>
            <Logo width={"100px"}/>
          </div>
          <div className={`container  ${
            this.state.animateContent ? "show-content" : ""}`}>
            <div className="container__2-col">
              <div></div>
              <div>
                <h2>{this.state.homeData.stage_title}</h2>
                <p dangerouslySetInnerHTML={{ __html: this.state.homeData.stage_description }} />
                <p dangerouslySetInnerHTML={{ __html: this.state.homeData.stage_location }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
