import React from 'react';
import { BrowserRouter} from 'react-router-dom';
// import AnimCards from './components/animcards';
import MainNav from './components/mainnav';
import Router from "./components/Router";
import Footer from './components/Footer';

function App() {
  return (
    <BrowserRouter basename="/iqmedia">
      <div className="App">
        <MainNav/>
        <Router/>
        <Footer value={"IQ-Media stagesite Mitch Goudkuil 2019"} />
      </div>
    </BrowserRouter>
  );
}

export default App;
